import React from "react";

const Card = ({ name, specialty, avatar, images }) => {
  const imgFiler = images.slice(0, 2);
  return (
    <>
      <div className="w-full rounded-xl sm:transform p-4 bg-white dark:bg-slate-800 border border-slate-100 dark:border-slate-700/80">
        <div className="items-center justify-center mb-4">
          <div className="grid grid-cols-2 gap-4">
            {imgFiler.map((img, index) => (
              <img
                key={index}
                className="h-20 w-full object-cover rounded-lg"
                src={img}
                alt=""
              />
            ))}
          </div>
          <div className="flex items-center justify-center -mt-10">
            <img
              className="h-16 w-16 rounded-full border-4 border-white bg-white dark:bg-slate-800 dark:border-slate-800 shadow-2xl dark:shadow-2xl dark:shadow-slate-700"
              src={avatar}
              alt=""
            />
          </div>
        </div>

        <div className="flex justify-between items-center text-slate-900 dark:text-white">
          <div>
            <p className="text-slate-500 dark:text-slate-400 text-sm">
              {specialty}
            </p>
            <p className="text-lg font-bold">{name}</p>
          </div>
          <div className="bg-slate-50 dark:bg-slate-700/30 p-3 rounded-full">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0761 5.57662C14.2714 5.38136 14.588 5.38136 14.7832 5.57662L20.8532 11.6466C20.947 11.7404 20.9997 11.8676 20.9997 12.0002C20.9997 12.1328 20.947 12.26 20.8532 12.3537L14.7832 18.4237C14.588 18.619 14.2714 18.619 14.0761 18.4237C13.8809 18.2285 13.8809 17.9119 14.0761 17.7166L19.7926 12.0002L14.0761 6.28373C13.8809 6.08847 13.8809 5.77188 14.0761 5.57662Z"
                className="fill-slate-900 dark:fill-white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 12C3 11.7239 3.22386 11.5 3.5 11.5L20.33 11.5C20.6061 11.5 20.83 11.7239 20.83 12C20.83 12.2761 20.6061 12.5 20.33 12.5L3.5 12.5C3.22386 12.5 3 12.2761 3 12Z"
                className="fill-slate-900 dark:fill-white"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
