import "./App.css";
import Seo from "./components/seo";
import Landing from "./pages/Landing/index";

function App() {
  Seo({
    title: "vahidabbasiy",
    metaDescription: "Adonix - Design, Develop and Publish Applications",
  });
  return (
    <>
      <div className="dark:bg-slate-800 bg-white">
        <Landing />
      </div>
    </>
  );
}

export default App;
