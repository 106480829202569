import React from "react";

const Index = () => {
  return (
    <>
      <div class=" lg:container mx-auto px-4 lg:px-24 text-gray-900">
        <h1 class="text-4xl lg:text-7xl font-black mb-10 text-slate-900  dark:text-white">
          OUR
          <span class="text-white dark:text-slate-800 outline-title">
            {" "}
            SERVICES
          </span>
        </h1>

        <div className="bg-slate-900 dark:bg-slate-900 rounded-xl flex justify-center items-center text-center py-12">
          <h1 class="text-xl lg:text-7xl font-black text-white">
            Our Businesses
            <span class="text-white dark:text-slate-900 outline-title2">
              {" "}
              Coming Soon
            </span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Index;
