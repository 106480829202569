import React from "react";
import Header from "./../../components/Header/index";
import TopHeader from "./../../components/TopHeader/index";
import OurServices from "./../../components/Services/index";
import Projects from "./../../components/projects/index";
import Team from "./../../components/Team/index";
import Comments from "./../../components/Comments/index";
import Footer from "./../../components/Footer/index";

const Index = () => {
  return (
    <>
      <div className="font-CustomF">
        <Header />
        <TopHeader />
        <OurServices />
        <Projects />
        <Team />
        <Comments />
        <Footer />
      </div>
    </>
  );
};

export default Index;
