import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectCard from "./card";

// swiper bundle styles
import "swiper/css/bundle";

// swiper core styles
import "swiper/css";

// modules styles
import "swiper/css/navigation";
import "swiper/css/pagination";

const projectData = [
  {
    id: 1,
    cover:
      "https://cdn.dribbble.com/users/2378116/screenshots/17150101/media/461b0d1aa06e21522ba13d7920770887.png?compress=1&resize=1200x900&vertical=top",
    title: "Loverto - NFT Marketplace",
    category: "UI/UX Design",
    type: "Real Project",
  },
  {
    id: 2,
    cover:
      "https://cdn.dribbble.com/users/268582/screenshots/15214477/media/e8febaf71f3a65a8b9d2be6d719688f7.jpg?compress=1&resize=1200x900&vertical=top",
    title: "Property Solutions & Services",
    category: "UI/UX Design",
    type: "Real Project",
  },
  {
    id: 3,
    cover:
      "https://cdn.dribbble.com/users/7202957/screenshots/17634429/media/c067cc7f753825aab5eaf8df2317c87f.jpg?compress=1&resize=1200x900&vertical=top",
    title: "Eneftis - NFTs Marketplace (White Version)",
    category: "UI/UX Design",
    type: "Real Project",
  },
  {
    id: 4,
    cover:
      "https://cdn.dribbble.com/users/3047488/screenshots/15401391/media/aca070433347d7cfbeee77cdac70689b.png?compress=1&resize=1200x900&vertical=top",
    title: "Cinema Dashboard Design",
    category: "UI/UX Design",
    type: "Concept Project",
  },
  {
    id: 5,
    cover:
      "https://cdn.dribbble.com/users/3047488/screenshots/15253058/media/0ea4302e742221b11ce6bbb3a514ba9b.png?compress=1&resize=1200x900&vertical=top",
    title: "Task Management Mobile App",
    category: "UI/UX Design",
    type: "Concept Project",
  },
];

const Index = () => {
  return (
    <>
      <div class="relative lg:container mx-auto px-4 lg:px-24 text-gray-900 dark:text-white py-24 md:py-44">
        <div className="flex items-center justify-between mb-10">
          <h1 class="text-4xl lg:text-7xl font-black">
            COMPLETED 🚀
            <span class="text-white dark:text-slate-800 outline-title">
              {" "}
              PROJECTS
            </span>
          </h1>
        </div>
        {/* Slider */}
        <Swiper
          spaceBetween={21}
          slidesPerView={4}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
              spaceBetween: 21,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 21,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 21,
            },
            // when window width is >= 1440px
            1440: {
              slidesPerView: 4,
              spaceBetween: 21,
            },
          }}
        >
          {projectData.map((item) => (
            <SwiperSlide key={item.id}>
              <ProjectCard
                title={item.title}
                cover={item.cover}
                category={item.category}
                type={item.type}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* End Slider */}
        <div className="absolute bottom-0 right-4 lg:right-24">
          <svg
            width="164"
            height="164"
            className="absolute w-30 h-32 md:w-40 md:h-40 -top-16 md:-top-20 right-40 md:right-48"
            viewBox="0 0 164 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M90.3227 95.2556C91.3827 95.9837 92.4534 96.7051 93.3896 97.261C110.036 107.264 130.668 113.505 150.073 112.535C150.776 112.494 151.342 111.924 151.306 111.214C151.27 110.504 150.685 109.988 149.981 110.029C131.009 110.871 110.929 104.799 94.7009 95.0418C94.1739 94.7369 93.5841 94.3462 92.9908 93.9579C94.2949 93.0831 95.1486 91.9813 95.6803 90.8216C96.4358 89.0452 96.4465 86.9683 95.8949 84.8502C95.1131 81.8904 93.2957 78.9199 91.0772 76.5459C89.3541 74.7388 87.3745 73.3231 85.6133 72.7562C84.1659 72.2517 82.8371 72.2882 81.7172 72.813C80.5406 73.3705 79.5559 74.5474 79.0936 76.6417C78.2369 80.182 79.3848 83.6621 81.6126 86.8834C83.2416 89.2323 85.4329 91.3777 87.6926 93.2406C86.7924 93.3948 85.78 93.4918 84.6519 93.534C76.0481 93.6283 64.866 88.3897 53.834 80.9926C37.0599 69.7229 20.6572 53.3655 14.3803 42.6139C14.061 42.0673 13.2437 41.808 12.6297 42.1625C12.0157 42.517 11.8362 43.3518 12.1554 43.8985C18.5915 54.9236 35.2708 71.6082 52.4167 83.1505C63.9455 90.8699 75.7256 96.2518 84.6818 96.0758C86.918 96.003 88.8113 95.7627 90.3227 95.2556ZM90.709 92.3521C92.0126 91.7213 92.8622 90.8655 93.3425 89.8573C93.8844 88.5698 93.809 87.0296 93.4332 85.541C92.7702 82.9999 91.1515 80.401 89.2281 78.344C87.8829 76.9278 86.3558 75.7389 84.956 75.207C84.1762 74.9262 83.4614 74.8509 82.8276 75.095C82.2346 75.4374 81.8429 76.1515 81.5506 77.1731C80.9151 80.0984 81.9736 82.8981 83.7245 85.4202C85.5373 88.0284 88.212 90.5045 90.709 92.3521Z"
              className="fill-slate-900 dark:fill-white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M149.889 110.116C142.568 111.297 135.261 114.905 129.386 119.394C128.829 119.837 128.712 120.635 129.106 121.139C129.551 121.735 130.371 121.871 130.927 121.429C136.505 117.233 143.373 113.756 150.31 112.674C150.989 112.526 151.474 111.88 151.377 111.205C151.226 110.44 150.568 109.967 149.889 110.116Z"
              className="fill-slate-900 dark:fill-white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M150.226 109.964C143.217 108.772 137.428 103.22 133.255 97.7103C132.862 97.2064 132.041 97.0714 131.484 97.5146C130.928 97.9573 130.813 98.7554 131.259 99.3507C135.763 105.278 142.167 111.205 149.817 112.514C150.503 112.605 151.151 112.109 151.276 111.427C151.403 110.745 150.911 110.055 150.226 109.964Z"
              className="fill-slate-900 dark:fill-white"
            />
          </svg>
          <button className="py-4 px-8 w-full sm:w-auto bg-slate-900 text-white transition hover:bg-lime-400 hover:text-slate-900 ease-in-out duration-300 rounded-xl font-CabinetGrotesk">
            Show All Project
          </button>
        </div>
      </div>
    </>
  );
};

export default Index;
