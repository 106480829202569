import React from "react";

const Card = ({ title, cover, category, type }) => {
  return (
    <>
      <div class="overflow-hidden">
        <div>
          <img
            src={cover}
            className="h-[234px] w-full rounded-xl mb-3 object-cover"
            alt=""
          />
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-slate-500 dark:text-slate-400">{category}</p>
          <div className="h-1 w-1 bg-slate-500 rounded-full"></div>
          <p className="text-slate-500 dark:text-slate-400">{type}</p>
        </div>
        <p className="text-3xl">{`${title.slice(0, 18)} ...`}</p>
      </div>
    </>
  );
};

export default Card;
