import React from "react";
import imgGif from "./../../assets/image/com-gif-maker-unscreen.gif";

const Index = () => {
  return (
    <>
      <div className="relative max-w-3xl mx-auto text-center py-40 lg:pt-48 lg:container lg:mx-auto px-4  lg:px-24 text-gray-900">
        <div>
          <svg
            className="absolute top-36 h-6 lg:h-fit lg:left-32"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 0L29.5863 9.63045C31.0051 18.2439 37.7561 24.9949 46.3696 26.4137L56 28L46.3696 29.5863C37.7561 31.0051 31.0051 37.7561 29.5863 46.3696L28 56L26.4137 46.3696C24.9949 37.7561 18.2439 31.0051 9.63044 29.5863L0 28L9.63045 26.4137C18.2439 24.9949 24.9949 18.2439 26.4137 9.63044L28 0Z"
              className="fill-slate-900 dark:fill-white"
            />
          </svg>
          <svg
            width="32"
            height="32"
            className="absolute top-48 h-4 lg:h-fit right-12 lg:right-48"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 0L16.9064 5.50311C17.7172 10.4251 21.5749 14.2828 26.4969 15.0936L32 16L26.4969 16.9064C21.5749 17.7172 17.7172 21.5749 16.9064 26.4969L16 32L15.0936 26.4969C14.2828 21.5749 10.4251 17.7172 5.50311 16.9064L0 16L5.50311 15.0936C10.4251 14.2828 14.2828 10.4251 15.0936 5.50311L16 0Z"
              fill="#A3E635"
            />
          </svg>
          {/* Bottom */}
          <svg
            width="32"
            height="32"
            className="absolute bottom-20 h-7 lg:bottom-48 lg:h-fit left-4 lg:left-24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 0L16.9064 5.50311C17.7172 10.4251 21.5749 14.2828 26.4969 15.0936L32 16L26.4969 16.9064C21.5749 17.7172 17.7172 21.5749 16.9064 26.4969L16 32L15.0936 26.4969C14.2828 21.5749 10.4251 17.7172 5.50311 16.9064L0 16L5.50311 15.0936C10.4251 14.2828 14.2828 10.4251 15.0936 5.50311L16 0Z"
              fill="#A3E635"
            />
          </svg>
          <svg
            width="54"
            height="54"
            className="absolute bottom-24 h-7 lg:bottom-60 lg:h-fit right-4 lg:right-24"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 0L28.5296 9.2865C29.8977 17.5924 36.4076 24.1023 44.7135 25.4704L54 27L44.7135 28.5296C36.4076 29.8977 29.8977 36.4076 28.5296 44.7135L27 54L25.4704 44.7135C24.1023 36.4076 17.5924 29.8977 9.2865 28.5296L0 27L9.2865 25.4704C17.5924 24.1023 24.1023 17.5924 25.4704 9.2865L27 0Z"
              className="fill-slate-900 dark:fill-white"
            />
          </svg>
        </div>
        <div>
          <h1 className="relative flex flex-col text-center lg:flex-row items-center justify-center lg:inline-flex font-black text-4xl lg:text-8xl leading-tight text-slate-900 dark:text-white">
            <img src={imgGif} className="flex lg:hidden h-40" alt="" />
            Halloosiiii
            <img
              src={imgGif}
              className="hidden lg:flex h-28 -ml-4 -mt-7 lg:h-40 lg:-mt-9 lg:-ml-4"
              alt=""
            />
          </h1>
        </div>
        <h1 className="font-black text-3xl lg:text-8xl leading-tight text-slate-900 dark:text-white">
          I’m
          <span className="text-3xl lg:text-8xl text-lime-500">
            {" "}
            Vahid Abbasiy
          </span>
        </h1>
        <h1 className="font-black text-3xl lg:text-8xl text-slate-900 dark:text-white">
          I’m
          <span className="before:block before:absolute ml-2 before:bg-lime-500 before:h-4 before:mt-5 lg:before:h-12 before:inset-0 sm:before:mt-12 relative inline-block">
            <span className="relative text-3xl lg:text-8xl">
              front end developer
            </span>
          </span>
        </h1>
        <p className="font-normal text-base text-slate-600 dark:text-slate-400 lg:text-2xl py-3">
          We are an award-winning branding & web agency committed to excellence
          forever.
        </p>
        <div>
          <p className="font-medium text-xl lg:text-4xl py-3 text-slate-900 dark:text-white">
            Do you have a project?
          </p>
          <button className="px-8 py-4 mt-6 animate-bounce  transition hover:scale-110 ease-in-out duration-300 bg-lime-400 text-slate-900 hover:bg-slate-900 hover:text-white rounded-full font-CabinetGrotesk text-xl sm:text-2xl">
            Let's talk!
          </button>
        </div>
      </div>
    </>
  );
};

export default Index;
