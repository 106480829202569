import React from "react";

const Card = ({ fullName, job, avatar, description, projectLink }) => {
  return (
    <>
      <div className="relative min-w-lg md:max-w-lg border border-slate-200 dark:border-slate-700/80 p-4 lg:p-10 rounded-2xl bg-white dark:bg-slate-800">
        <div>
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-4">
              <img
                className="h-8 w-8 lg:h-14 lg:w-14 rounded-full shadow-2xl"
                src={avatar}
                alt=""
              />
              <div>
                <p className="text-sm font-semibold lg:text-xl">{fullName}</p>
                <p className="text-xs lg:text-sm lg:font-normal text-slate-600 dark:text-slate-500">
                  {job}
                </p>
              </div>
            </div>
            <a
              href={projectLink}
              className="text-xs flex items-center justify-center rounded-full h-7 w-28 bg-lime-400 hover:bg-lime-500 text-slate-900 transition ease-in-out duration-300"
            >
              View Project
            </a>
          </div>
          <div>
            <p className="text-base lg:text-xl text-slate-800 dark:text-slate-400">
              {description}
            </p>
          </div>
        </div>
        <div className="absolute flex items-center justify-center left-0 top-0 h-full my-auto">
          <svg
            className="absolute -left-[1px] bottom-auto top-auto"
            width="4"
            height="84"
            viewBox="0 0 4 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V0C2.20914 0 4 1.79086 4 4V96C4 98.2091 2.20914 100 0 100V100V0Z"
              fill="#A3E635"
            />
          </svg>
        </div>
        <div className="absolute flex items-center justify-center right-0 top-0 h-full my-auto">
          <svg
            className="absolute -right-[1px] bottom-auto top-auto"
            width="4"
            height="84"
            viewBox="0 0 4 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0V100C1.79086 100 0 98.2091 0 96V4Z"
              fill="#A3E635"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Card;
